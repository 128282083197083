import ApplicationController from "./application_controller"
import StimulusReflex from 'stimulus_reflex'

export default class extends ApplicationController {
    connect () {
        console.log("ReplyFormController connected")
        document.addEventListener("replyCreated", this.handleReplyCreated)
    }

    disconnect() {
        console.log("ReplyFormController disconnected")
        document.removeEventListener("replyCreated", this.handleReplyCreated)
      }

    handleReplyCreated = (event) => {
        // Your JavaScript function code here
        console.log("Custom event received:", event.detail)
        // For example, reset the form
        this.element.reset()
        // Or perform any other actions
      }

//     afterReflex(element, reflex, error, id) {
//         debugger
//         console.log('Genial', reflex.element, this.element)
// console.log('afterReflex called');
//         if (element === this.element) {
//           this.element.reset()
//         }
//       }

//       reflexSuccess(element, reflex, noop, reflexId) {
//         console.log('reflexSuccess called')
//         if (reflex.element === this.element) {
//           this.element.reset()
//         }
//       }

//     afterCreateReply(element, reflex) {
//         console.log('afterCreateReply called')
//         this.element.reset()
//     }
}