// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("trix")

global.$ = require('jquery')

require('jquery')
require('jquery-ui')
require('@nathanvda/cocoon')
require('../theme/intl-tel-input')

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)


// ----------------------------------------------------
// Note(lewagon): ABOVE IS RAILS DEFAULT CONFIGURATION
// WRITE YOUR OWN JS STARTING FROM HERE 👇
// ----------------------------------------------------

// External imports
import "bootstrap";
import '../theme/theme';
import '../application/swalFire';
import scroll from '../custom/scroll-navbar';
import {autocompleteForm} from '../custom/autocomplete';
import result from '../custom/result';
import cookieBanner from '../custom/cookieBanner';
import {videoAskHomepage} from '../custom/videoAsk';
import googleReviews from '../custom/googleReviews';
import {cookiesGenerator} from '../custom/cookiesGenerator';
import displayData from '../custom/displayData';
import behaviorForm from '../custom/behaviorForm';
import {animatePictures} from '../custom/animatePictures';
import videoSelection from '../custom/videoSelection';
import count from '../custom/countUp';
import displayBillAddress from '../custom/displayBillAddress';
import copyToClipboardLink from '../custom/copyToClipboardLink';
import addressFormat from '../custom/addressFormat';
import {adminAddressAutocomplete} from '../admin/adminAddressAutocomplete';
import simpleFormTelephone from '../custom/simpleFormTelephone';
import offerPriceType from '../custom/offerPrice';
import spinner from '../custom/spinner';
import fireworksHappiness from '../custom/fireworksHappiness';
import initElectricityProductionGraph from '../custom/electricityProductionGraph';
import scrollSpy from '../custom/scrollSpy';
import selectRoof from '../custom/selectRoof';
import fillElementsFromCookies from '../custom/fillElementsFromCookies';
import clientValidation from '../custom/clientValidation';
import typeHouse from '../custom/typeHouse';
import signaturePad from '../custom/signaturePad';
import { cloneElement, removeElement } from '../custom/elementsMultiplier';
import {selectButton} from '../common_files/disableButton';
import copyNameInstaller from '../custom/copyNameInstaller';
import directUploads from '../common_files/direct_uploads';
import imageCompressor from '../common_files/imageCompressor';
import enableButton from '../common_files/enableUploadButton';
import pickDate from '../common_files/pickDate';
import toggleFormByRadio from '../custom/toggleFormByRadio';
import shortContractPdf from '../custom/shortContractPdf';
import openModalWhenScrollTo from '../custom/openModalWhenScrollTo';
import formWithSteps from '../custom/formWithSteps';
import submitFormsRemotely from '../custom/submitFormsRemotely';
import updateActivity from '../custom/updateActivity';
import {addInputs, removeInputs} from "../custom/addRemoveInputs";
import savingsAndGrants from '../custom/savingsAndGrants';
import deselectableRadios from '../custom/deselectableRadios';
import SolarPanelsMap from '../custom/google-solar/panels/index';
import inputNumber from '../custom/inputNumber';
import {pickDateSeveralInputs} from '../admin/pickDateSeveralInputs';
import elementCloneOrRemove from '../custom/elementCloneOrRemove';


document.addEventListener('turbolinks:load', () => {
  if (!!document.getElementById('addInputs')) {
    addInputs();
    removeInputs();
  }

  if (!!document.querySelector('.displayMap')) {
    displayMap();
  }

  if (!!document.getElementById('datepicker')) {
    pickDate();
  }

  if (!!document.querySelector('.datepicker')) {
    pickDateSeveralInputs();
  }

  if (!!document.getElementById('short-contract')) {
    shortContractPdf();
  }

  if (!!document.querySelector('.signature-wrapper')) {
    signaturePad();
  }

  if (!!document.getElementById('visit_installer_responsible')) {
    copyNameInstaller();
  }

  if (document.getElementById('navbar')) {
    scroll();
  }

  // if (document.getElementById('calculator-form')) {
  //   cookiesGenerator();
  // }

  const homepage = document.getElementById('homepage');
  if (homepage != null) {
    videoAskHomepage();
  }

  if (!!document.getElementById('address')) {
    autocompleteForm();
    displayData();
  }

  const confirmPage = document.getElementById('confirm-page');
  if (confirmPage != null) {
    behaviorForm();
    displayBillAddress();
    addressFormat();
    autocompleteForm();
    fillElementsFromCookies();
  }

  if (!!document.getElementById('lead_form')) {
    clientValidation();
  }

  if (!!document.getElementById('landing-page')) {
    autocompleteForm();
  }

  if (document.getElementById('how-it-works')) {
    animatePictures();
  }

  if (document.getElementById('contract')) {
    adminAddressAutocomplete();
  }

  if (!!document.getElementById('offer')) {
    offerPriceType();
    initElectricityProductionGraph();
  }

  const cookie = document.querySelector('#cookie');
  if (cookie) {
    cookieBanner(cookie);
  }

  const about = document.getElementById('about-us');
  if (about != null) {
    videoSelection();
    count();
    autocompleteForm();
  }

  const contact = document.getElementById('contact');
  if (contact) {
    googleReviews();
  }

  if (document.getElementById('ambassador-page')) {
    copyToClipboardLink('copyBtn');
  }

  if (!!document.querySelector('.intl-phone.contract') || !!document.querySelector('.intl-phone.landing')) {
    simpleFormTelephone();
  }

  if (document.querySelector('.autocompleteAddress1')) {
    adminAddressAutocomplete();
  }

  if (document.querySelector('.spinner')) {
    spinner();
  }

  if (document.querySelector('.fireworks-container')) {
    fireworksHappiness();
  }

  if (document.querySelector('.scrollspy-target')) {
    scrollSpy();
  }

  if (document.getElementById('select-roof-page')) {
    addressFormat();
    selectRoof();
  }

  if (document.getElementById('get-quote-page')) {
    behaviorForm();
    addressFormat();
    autocompleteForm();
    result();
    fillElementsFromCookies();
  }

  if (!!document.getElementById('select-house')) {
    typeHouse();
  }

  if (document.querySelector('.clone-element')) {
    cloneElement();
  }

  if (document.querySelector('.remove-element')) {
    removeElement();
  }

  if (!!document.querySelector('.disabling')) {
    selectButton();
  }

  if (!!document.querySelector('.optimize-images')) {
    imageCompressor();
  }

  if (!!document.querySelector('.direct_uploads')) {
    directUploads();
  }

  if (!!document.querySelector('.upload-file')) {
    enableButton();
  }

  if (!!document.querySelector('.article')) {
    const container = document.querySelector('.article-container');

    container.querySelectorAll('a').forEach(link => link.target = '_blank');
  }

  if (!!document.querySelector('.submit-remotely')) {
    submitFormsRemotely();
  }

  if (!!document.querySelector('[data-activity-public-id]')) {
    updateActivity(document.querySelector('[data-activity-public-id]').dataset.activityPublicId);
  }

  if (!!document.querySelector('#savings-and-grants')) {
    savingsAndGrants();
  }

  if (!!document.querySelector('#solar-panels')) {
    const element = document.getElementById("solar-panels");
    const solarPanelsMap = new SolarPanelsMap("AIzaSyCHRoQipzAfaLyf9TFme0z6TZxyNy-QYVA", element);
  }

  if (!!document.querySelector('.input-number-wrapper')) {
    inputNumber();
  }

  toggleFormByRadio();
  openModalWhenScrollTo('additionalDetailsFormModal', 'scrollSolarKit');
  formWithSteps();
  deselectableRadios();
  elementCloneOrRemove();
});

import "controllers"
import "config"
import "channels"
