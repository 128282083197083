import CableReady from 'cable_ready'
import consumer from '../channels/consumer'

consumer.subscriptions.create('RepliesChannel', {
    received(data) {
        if (data.cableReady) {
            CableReady.perform(data.operations)
            console.log("Received CableReady operations:", data.operations)
        }
    }
})